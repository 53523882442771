import styled from "@emotion/styled";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { Flex } from "reflexbox/styled-components";
import AppContainer from "../components/container";
import { rgba } from "polished";
import { Helmet } from "react-helmet";
import AppPageTitle from "../components/page-title";
import format from "date-fns/format";
import { useTheme } from "@emotion/react";
import AppMarkdown from "../components/markdown";

const Header = styled(Flex)(({ theme }) => ({
  minHeight: 250,
  maxHeight: 500,
  width: "100%",
  background: `linear-gradient(180deg, ${theme.colors.primary.light} 0%, ${theme.colors.primary.main} 100%)`,
  padding: `${theme.spacing(2)}px ${theme.pagePadMobile}px`,
  boxSizing: "border-box",
  "&&": {
    marginBottom: theme.spacing(4),
  },
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
    height: "30vh",
  },
}));

const HeaderContainer = styled(AppContainer)(({ theme }) => ({
  alignItems: "center",
  [theme.mq.desktop]: {
    alignItems: "flex-start",
  },
}));

const TitleWrap = styled(Flex)(({ theme }) => ({
  borderBottomColor: rgba(theme.colors.primary.contrastText, 0.8),
  borderBottomStyle: "solid",
  borderBottomWidth: 4,
  paddingBottom: theme.spacing(1) / 2,
  flexDirection: "column",
  marginBottom: theme.spacing(1),
  [theme.mq.desktop]: {
    flexDirection: "row",
    marginBottom: 0,
  },
}));

const Title = styled.h1(({ theme }) => ({
  color: theme.colors.secondary.contrastText,
  fontWeight: 500,
  fontSize: 40,
  margin: 0,
  [theme.mq.desktop]: {
    fontSize: 65,
  },
}));

const Subtitle = styled.p(({ theme }) => ({
  color: theme.colors.secondary.contrastText,
  fontSize: 19,
  width: "80%",
}));

const Icon = styled.img(({ theme }) => ({
  height: 50,
  [theme.mq.desktop]: {
    marginRight: theme.spacing(2),
  },
}));

const Content = styled.article(({ theme }) => ({
  padding: `0 ${theme.pagePadMobile}px`,
  "&&": {
    marginBottom: theme.spacing(4),
  },
  [theme.mq.desktop]: {
    padding: `0 ${theme.pagePad}px`,
  },
}));

const AppNewsItemTemplate = ({
  data,
}: PageProps<GatsbyTypes.NewsItemPageQuery>) => {
  const theme = useTheme();

  if (!data.markdownRemark?.frontmatter) return <></>;

  const { title, text, date } = data.markdownRemark?.frontmatter;

  return (
    <AppContainer
      flexDirection="column"
      alignItems="center"
      breakpoint="desktop"
    >
      <Helmet title={`RWS | ${title}`}></Helmet>
      <Content>
        <AppPageTitle>{title}</AppPageTitle>
        <h1
          style={{
            marginBottom: theme.spacing(1) / 2,
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          {title}
        </h1>
        <span style={{ opacity: 0.7, fontSize: 14, fontWeight: 300 }}>
          {format(new Date(date ?? ""), "dd/MM/yy")}
        </span>
        <AppMarkdown css={{ fontWeight: 300 }} content={text} />
      </Content>
    </AppContainer>
  );
};

export default AppNewsItemTemplate;

export const query = graphql`
  query NewsItemPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        text
      }
    }
  }
`;
